// extracted by mini-css-extract-plugin
export var block = "Voice-module--block--Z7y7n";
export var top = "Voice-module--top--HDhML";
export var bottom = "Voice-module--bottom--8ZAOJ";
export var contentWrap = "Voice-module--contentWrap--2Xpo1";
export var content = "Voice-module--content--w5mT+";
export var heading = "Voice-module--heading--SNbMv";
export var headingIcon = "Voice-module--headingIcon--1+Mt1";
export var headingImgWrap = "Voice-module--headingImgWrap--1bevA";
export var headingImg = "Voice-module--headingImg--A0He0";
export var headingText = "Voice-module--headingText--bAETC";
export var list = "Voice-module--list--5WhPo";
export var item = "Voice-module--item--IIslJ";
export var data = "Voice-module--data--l23OU";
export var img = "Voice-module--img--7ZYYb";
export var text = "Voice-module--text--U29tt";
export var linkWrap = "Voice-module--linkWrap--sKiuY";