import React from 'react';

import * as style from './QuestionBox.module.scss';

import {
  Answer,
  ArrowDown,
  ArrowUp,
  Question,
} from '@/components/modules/Icon';

interface Props {
  q: string;
  a: string;
}

export default function QuestionBox({ q, a }: Props) {
  const [isOpen, setIsOpen] = React.useState(false);

  return (
    <li className={style.block}>
      <button
        className={style.button}
        type="button"
        onClick={() => setIsOpen(!isOpen)}
      >
        <Question className={style.questionIcon} />
        <span className={style.questionText}>{q}</span>
        {isOpen ? (
          <ArrowUp className={style.arrow} />
        ) : (
          <ArrowDown className={style.arrow} />
        )}
      </button>
      {isOpen && (
        <div className={style.answer}>
          <Answer className={style.answerIcon} />
          <p
            className={style.answerText}
            dangerouslySetInnerHTML={{ __html: a.replace(/\r?\n/g, `<br />`) }}
          />
        </div>
      )}
    </li>
  );
}
