// extracted by mini-css-extract-plugin
export var block = "Why-module--block--8KF62";
export var heading = "Why-module--heading--9lhwm";
export var headingIcon = "Why-module--headingIcon--FEHp0";
export var headingImgWrap = "Why-module--headingImgWrap---7sL0";
export var headingImg = "Why-module--headingImg--IgGnK";
export var sectionGroup = "Why-module--sectionGroup--o22Rz";
export var section = "Why-module--section--mLOwl";
export var imgWrap = "Why-module--imgWrap--IBLmo";
export var img = "Why-module--img--wF9fE";
export var textWrap = "Why-module--textWrap--Pq4VU";
export var textInner = "Why-module--textInner--0Qw9X";
export var sectionHeading = "Why-module--sectionHeading--PjfP8";
export var paragraph = "Why-module--paragraph--A+ewU";
export var list = "Why-module--list--DoT+4";
export var em = "Why-module--em--0L0zL";
export var note = "Why-module--note--IzZRV";
export var linkWrap = "Why-module--linkWrap--X5MvE";