// extracted by mini-css-extract-plugin
export var block = "Qa-module--block--WMCB2";
export var heading = "Qa-module--heading--1+qC8";
export var headingIcon = "Qa-module--headingIcon--Zid1l";
export var headingImgWrap = "Qa-module--headingImgWrap--ooQ21";
export var headingImg = "Qa-module--headingImg--1Jl-P";
export var headingText = "Qa-module--headingText---d8Jl";
export var sectionList = "Qa-module--sectionList--xwSp8";
export var section = "Qa-module--section--C3z+f";
export var sectionHeading = "Qa-module--sectionHeading--34hQk";
export var linkWrap = "Qa-module--linkWrap--wmQlA";