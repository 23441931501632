import React from 'react';

import * as style from './Footer.module.scss';
import { MainLogo } from '@/components/modules/Icon';

export default function Footer() {
  return (
    <footer className={style.footer}>
      <MainLogo className={style.logo} />
      <p className={style.text}>チャットレディの税金サポーター</p>
      <span className={style.copy}>© 2021 Chatsupo</span>
    </footer>
  );
}
