import React from 'react';

function Question(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M11.9924 23.9849C13.6379 23.9849 15.1849 23.6745 16.6334 23.0536C18.082 22.4328 19.359 21.5672 20.4643 20.4568C21.5697 19.3464 22.4353 18.0694 23.0612 16.6259C23.6871 15.1823 24 13.6379 24 11.9924C24 10.347 23.6871 8.80253 23.0612 7.359C22.4353 5.91547 21.5672 4.6385 20.4568 3.52809C19.3464 2.41768 18.0694 1.55206 16.6259 0.931221C15.1823 0.310407 13.6328 0 11.9773 0C10.342 0 8.8 0.310407 7.35142 0.931221C5.90285 1.55206 4.6284 2.41768 3.52808 3.52809C2.42776 4.6385 1.56466 5.91547 0.9388 7.359C0.312934 8.80253 0 10.347 0 11.9924C0 13.6379 0.312934 15.1823 0.9388 16.6259C1.56466 18.0694 2.43028 19.3464 3.53565 20.4568C4.64101 21.5672 5.91798 22.4328 7.36656 23.0536C8.81515 23.6745 10.3571 23.9849 11.9924 23.9849ZM11.9924 22.2133C10.5792 22.2133 9.25426 21.9508 8.01766 21.4259C6.78107 20.901 5.6959 20.1691 4.76215 19.2303C3.8284 18.2915 3.09906 17.2038 2.57413 15.9672C2.04922 14.7306 1.78676 13.4057 1.78676 11.9924C1.78676 10.5792 2.04922 9.25427 2.57413 8.01767C3.09906 6.78108 3.8284 5.69338 4.76215 4.75456C5.6959 3.81577 6.77855 3.08391 8.01009 2.55901C9.24163 2.03408 10.564 1.77161 11.9773 1.77161C13.4006 1.77161 14.7306 2.03408 15.9672 2.55901C17.2038 3.08391 18.2889 3.81577 19.2227 4.75456C20.1565 5.69338 20.8908 6.78108 21.4259 8.01767C21.9609 9.25427 22.2284 10.5792 22.2284 11.9924C22.2284 13.4057 21.9634 14.7306 21.4334 15.9672C20.9035 17.2038 20.1716 18.2915 19.2379 19.2303C18.3041 20.1691 17.2189 20.901 15.9823 21.4259C14.7457 21.9508 13.4158 22.2133 11.9924 22.2133Z" />
      <path d="M11.9926 17.5344C12.6992 17.5344 13.3402 17.4133 13.9156 17.171L14.7787 18.3369C14.9705 18.5994 15.1926 18.7306 15.445 18.7306C15.6267 18.7306 15.7781 18.6726 15.8992 18.5565C16.0204 18.4404 16.0809 18.2865 16.0809 18.0947C16.0809 17.9332 15.9951 17.7414 15.8235 17.5193L15.0513 16.4896C15.6771 16.0051 16.1667 15.3691 16.52 14.5817C16.8734 13.7943 17.05 12.8959 17.05 11.8865C17.05 10.7962 16.838 9.82716 16.414 8.9792C15.9901 8.13125 15.397 7.47005 14.6349 6.9956C13.8727 6.52116 12.992 6.28394 11.9926 6.28394C10.9932 6.28394 10.1125 6.52116 9.35033 6.9956C8.58819 7.47005 7.99513 8.13125 7.57115 8.9792C7.14717 9.82716 6.93518 10.7962 6.93518 11.8865C6.93518 13.007 7.14717 13.9912 7.57115 14.8391C7.99513 15.6871 8.58819 16.3483 9.35033 16.8227C10.1125 17.2972 10.9932 17.5344 11.9926 17.5344ZM11.9926 16.1868C10.973 16.1868 10.1529 15.7956 9.53202 15.0133C8.91121 14.2309 8.6008 13.1887 8.6008 11.8865C8.6008 10.6044 8.91121 9.57225 9.53202 8.78991C10.1529 8.0076 10.973 7.61644 11.9926 7.61644C13.0122 7.61644 13.8324 8.0076 14.4532 8.78991C15.074 9.57225 15.3844 10.6044 15.3844 11.8865C15.3844 12.6335 15.2784 13.2947 15.0664 13.8701C14.8544 14.4455 14.5516 14.9199 14.1579 15.2934L13.4008 14.2486C13.2393 14.0366 13.0424 13.9306 12.8103 13.9306C12.6488 13.9306 12.5024 13.9887 12.3711 14.1048C12.2399 14.2208 12.1743 14.3647 12.1743 14.5363C12.1743 14.7079 12.245 14.8896 12.3863 15.0814L13.0677 16.0202C12.7547 16.1313 12.3964 16.1868 11.9926 16.1868Z" />
    </svg>
  );
}

export default Question;
