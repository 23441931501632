import React from 'react';

function ArrowUp(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M12.009 5C12.3331 5 12.6151 5.13203 12.8552 5.39609L23.6579 16.6129C23.886 16.841 24 17.105 24 17.4051C24 17.7292 23.892 17.9992 23.6759 18.2153C23.4599 18.4313 23.1958 18.5394 22.8837 18.5394C22.5716 18.5394 22.2956 18.4313 22.0555 18.2153L11.3068 7.07051H12.6932L1.92647 18.2153C1.71042 18.4313 1.44636 18.5394 1.13429 18.5394C0.810195 18.5394 0.540124 18.4313 0.324071 18.2153C0.108024 17.9992 0 17.7292 0 17.4051C0 17.105 0.114034 16.835 0.342102 16.5949L11.1448 5.3781C11.3968 5.12603 11.6849 5 12.009 5Z" />
    </svg>
  );
}

export default ArrowUp;
