// extracted by mini-css-extract-plugin
export var block = "PriceTable-module--block--hZkEm";
export var heading = "PriceTable-module--heading--0ONKU";
export var subSection = "PriceTable-module--subSection--nKuUK";
export var subSectionParagraph = "PriceTable-module--subSectionParagraph--HuzVy";
export var pcBreak = "PriceTable-module--pcBreak--JDrCw";
export var priceSection = "PriceTable-module--priceSection--PPt5l";
export var priceHeading = "PriceTable-module--priceHeading--yjvph";
export var exampleList = "PriceTable-module--exampleList--V6Sr5";
export var exampleItem = "PriceTable-module--exampleItem--+eUhX";
export var exampleText = "PriceTable-module--exampleText--0kW7q";
export var amount = "PriceTable-module--amount--SUnE3";
export var amountPrice = "PriceTable-module--amountPrice--y6Inm";
export var amountUnit = "PriceTable-module--amountUnit--VT6j9";
export var amountTax = "PriceTable-module--amountTax--1hbkp";