import React from 'react';

function Yen(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M11.9924 23.9849C13.6379 23.9849 15.1849 23.6745 16.6334 23.0536C18.082 22.4328 19.359 21.5672 20.4643 20.4568C21.5697 19.3464 22.4353 18.0694 23.0612 16.6259C23.6871 15.1823 24 13.6379 24 11.9924C24 10.347 23.6871 8.80253 23.0612 7.359C22.4353 5.91547 21.5672 4.6385 20.4568 3.52809C19.3464 2.41768 18.0694 1.55206 16.6259 0.931221C15.1823 0.310407 13.6328 0 11.9773 0C10.342 0 8.8 0.310407 7.35142 0.931221C5.90285 1.55206 4.6284 2.41768 3.52808 3.52809C2.42776 4.6385 1.56466 5.91547 0.9388 7.359C0.312934 8.80253 0 10.347 0 11.9924C0 13.6379 0.312934 15.1823 0.9388 16.6259C1.56466 18.0694 2.43028 19.3464 3.53565 20.4568C4.64101 21.5672 5.91798 22.4328 7.36656 23.0536C8.81515 23.6745 10.3571 23.9849 11.9924 23.9849ZM11.9924 22.2133C10.5792 22.2133 9.25426 21.9508 8.01766 21.4259C6.78107 20.901 5.6959 20.1691 4.76215 19.2303C3.8284 18.2915 3.09906 17.2038 2.57413 15.9672C2.04922 14.7306 1.78676 13.4057 1.78676 11.9924C1.78676 10.5792 2.04922 9.25427 2.57413 8.01767C3.09906 6.78108 3.8284 5.69338 4.76215 4.75456C5.6959 3.81577 6.77855 3.08391 8.01009 2.55901C9.24163 2.03408 10.564 1.77161 11.9773 1.77161C13.4006 1.77161 14.7306 2.03408 15.9672 2.55901C17.2038 3.08391 18.2889 3.81577 19.2227 4.75456C20.1565 5.69338 20.8908 6.78108 21.4259 8.01767C21.9609 9.25427 22.2284 10.5792 22.2284 11.9924C22.2284 13.4057 21.9634 14.7306 21.4334 15.9672C20.9035 17.2038 20.1716 18.2915 19.2379 19.2303C18.3041 20.1691 17.2189 20.901 15.9823 21.4259C14.7457 21.9508 13.4158 22.2133 11.9924 22.2133Z" />
      <path d="M11.9923 17.8826C12.1942 17.8826 12.3557 17.8221 12.4768 17.7009C12.598 17.5798 12.6585 17.4082 12.6585 17.1861V15.3539H14.8995C15.2529 15.3539 15.4295 15.1773 15.4295 14.824C15.4295 14.4606 15.2529 14.2788 14.8995 14.2788H12.6585V13.3552H14.8995C15.2529 13.3552 15.4295 13.1735 15.4295 12.8101C15.4295 12.4568 15.2529 12.2801 14.8995 12.2801H13.1734L16.0806 7.63154C16.1715 7.5003 16.2169 7.35393 16.2169 7.19243C16.2169 7.02081 16.1538 6.87444 16.0276 6.75331C15.9015 6.63217 15.7425 6.57159 15.5506 6.57159C15.2882 6.57159 15.0813 6.68768 14.9298 6.91987L11.9923 11.7956L9.03961 6.90471C8.96894 6.78358 8.88567 6.69526 8.78978 6.63975C8.69388 6.58422 8.58031 6.55646 8.44907 6.55646C8.25728 6.55646 8.09576 6.61702 7.96453 6.73815C7.83331 6.8593 7.7677 7.00568 7.7677 7.17729C7.7677 7.3388 7.81312 7.49021 7.90396 7.63154L10.8112 12.2801H9.1759C8.82258 12.2801 8.64592 12.4568 8.64592 12.8101C8.64592 12.9615 8.69388 13.0902 8.78978 13.1962C8.88567 13.3022 9.01437 13.3552 9.1759 13.3552H11.3261V14.2788H9.1759C8.82258 14.2788 8.64592 14.4606 8.64592 14.824C8.64592 14.9754 8.69388 15.1016 8.78978 15.2025C8.88567 15.3035 9.01437 15.3539 9.1759 15.3539H11.3261V17.1861C11.3261 17.3981 11.3891 17.5672 11.5153 17.6934C11.6415 17.8195 11.8005 17.8826 11.9923 17.8826Z" />
    </svg>
  );
}

export default Yen;
