import React from 'react';

import * as style from './Step.module.scss';

interface Props {
  step: number;
  title: string;
  description: string;
}

export default function Step(props: Props) {
  const { step, title, description } = props;
  const type = step % 2 === 0 ? `provider` : `customer`;
  const typeJa = step % 2 === 0 ? `チャトサポ` : `お客様`;
  return (
    <section className={style.block} data-type={type}>
      <div className={style.content}>
        <div className={style.imgWrap}>
          <div className={style.stepText}>
            <span className={style.step}>Step {step}</span>
            <span className={style.stepType}>{typeJa}</span>
          </div>
          <img
            alt=""
            className={style.img}
            src={`/common/section_2/${step}.jpg`}
            loading="lazy"
          />
        </div>
        <div className={style.textWrap}>
          <h2 className={style.textHeading}>{title}</h2>
          <p className={style.textParagraph}>{description}</p>
        </div>
      </div>
    </section>
  );
}
