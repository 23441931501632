export const QA_LIST = [
  {
    title: `副業の場合`,
    contents: [
      {
        q: `会社員しながら副業でチャットレディを始めました。どういった場合に確定申告が必要でしょうか？`,
        a: `チャットレディの所得が20万円を超える時には、確定申告が必要になります。`,
      },
      {
        q: `会社にバレないでしょうか？`,
        a: `「チャットレディがバレる」＝「副業がバレている」ということです。つまり、副業がバレないようにする必要があります。`,
      },
      {
        q: `会社に副業がばれないようにするにはどうしたらいいでしょうか？`,
        a: `副業は、給与から天引きされる住民税によってバレることが多いです。\n対策としては、確定申告の際に、「住民税の納付の方法」という欄項目に「住民税を自分で払う」にチェックを入れます。\nそうすることにより、副業分の住民税は給与の天引きされなくなり、副業（チャットレディ）がバレにくくなります。`,
      },
    ],
  },
  {
    title: `主婦の方`,
    contents: [
      {
        q: `主婦をしながら合間にチャットレディをしています。どういった場合に確定申告が必要でしょうか？`,
        a: `チャットレディの所得が48万円を超える時は確定申告が必要となります。`,
      },
      {
        q: `夫にバレないか心配です。どうしたらいいでしょうか？`,
        a: `確定申告をすること＝チャットレディがバレるということはあまりありません。\nしかし、それまで確定申告していなかった方が急に確定申告を始めると疑問を持たれることはあると思います。\n対策としては、事前に”何かしら事業を始めため確定申告をすることになった”、ということを先に話しておいた方が良いと思います。\nまた、チャットレディをしていること自体がバレるかバレないかは、ご本人様次第のところが大きいです。\n例えば、お仕事する時間帯や洋服、下着、お金使いなどには注意する必要があると思います。\n詳細が知りたい場合には、当事務所までご相談ください。`,
      },
      {
        q: `旦那の扶養から外れないか心配です。どうしたらいいでしょうか？`,
        a: `扶養と一口に言っても、「税務上の扶養」と「社会保険上の扶養」と2種類あり、それぞれ要件が異なります。\nそれぞれの要件に当てはまらないように気を付けて働く必要があります。\n・税務上の扶養\n所得が48万円以下：税務上の扶養の範囲内（配偶者控除の適用あり）\n所得が133万円以下：扶養からは外れるが、配偶者特別控除の適用あり\n・社会保険上の扶養\n収入130万円未満の場合：社会保険の扶養の範囲内\n※なお、社会保険上の扶養の範囲は、勤め先の健康保険組合によって要件が異なるため、夫の勤め先（健康保険組合）に確認されることをお勧めいたします。`,
      },
    ],
  },
  {
    title: `学生の方`,
    contents: [
      {
        q: `学生でチャットレディの仕事をしています。どういった場合に確定申告が必要でしょうか？`,
        a: `２パターンあります。\n①チャットレディの所得が、48万円（2019年分までは38万円）を超える時。\n②他にアルバイトをしていれば、チャットレディの所得が20万円を超える時は確定申告が必要となります。`,
      },
      {
        q: `学生でチャットレディの仕事をしています。親の扶養から外れないか心配です。どうしたらいいでしょうか？`,
        a: `扶養と一口に言っても、「税務上の扶養」と「社会保険上の扶養」と2種類あり、それぞれ要件が異なります。\nそれぞれの要件に当てはまらないように気を付けて働く必要があります。\n・税務上の扶養\n所得が48万円以下：税務上の扶養の範囲内（配偶者控除の適用あり）\n所得が133万円以下：扶養からは外れるが、配偶者特別控除の適用あり\n・社会保険上の扶養\n収入130万円未満の場合：社会保険の扶養の範囲内\n※なお、社会保険上の扶養の範囲は、勤め先の健康保険組合によって要件が異なるため、親の勤め先（健康保険組合）に確認されることをお勧めいたします。`,
      },
    ],
  },
  {
    title: `弊社への質問`,
    contents: [
      {
        q: `税理士への支払いは高いですか？`,
        a: `弊社へご依頼いただいた場合には、8万円（税別）～※です。\n青色申告の優遇などによりご自身で確定申告するよりも税金が安くなることがありますので、税理士へご依頼されることをお勧めいたします。\n※収入の種類や金額、取引数に応じて変動します。詳細はお問い合わせください。`,
      },
    ],
  },
];
