import React from 'react';

import * as style from '@/components/Price.module.scss';
import { Yen } from '@/components/modules/Icon';
import ContactLink from '@/components/modules/ContactLink';
import Step from '@/components/modules/Step';
import PriceTable from '@/components/modules/PriceTable';

export default function Price() {
  return (
    <section id="price" className={style.block}>
      <div className={style.top} />
      <div className={style.contentWrap}>
        <div className={style.content}>
          <div className={style.heading}>
            <Yen className={style.headingIcon} />
            <div className={style.headingImgWrap}>
              <img
                alt="流れ・料金"
                className={style.headingImg}
                src="/common/heading/2.svg"
              />
            </div>
          </div>
          <section className={style.section}>
            <div className={style.sectionHeadingGroup}>
              <div className={style.sectionHeadingWrap}>
                <div className={style.sectionHeading}>お客様</div>
              </div>
              <div className={style.sectionHeadingWrap}>
                <div className={style.sectionHeading}>チャトサポ</div>
              </div>
            </div>
            <div>
              <Step
                step={1}
                title="ご相談"
                description="確定申告についてお悩みでしたら、お問い合わせボタンからお気軽にご相談ください。初回のオンライン面談は無料です。"
              />
              <Step
                step={2}
                title="ご面談"
                description="オンラインでご面談し、チャトサポのサービス内容などをご説明いたします。"
              />
              <Step
                step={3}
                title="ご連絡と情報共有"
                description="何かあれば、いつでもチャットでお気軽にご連絡ください。スマホでチャトサポとやりとりできるため、楽にスムーズに連絡できます。"
              />
              <Step
                step={4}
                title="作業と完了報告"
                description="確定申告書の作成まですべてチャトサポが対応いたします。そして税金の連絡と請求書を発行させていただきます。"
              />
              <Step
                step={5}
                title="確認と振込"
                description="確定申告書の内容をご確認いただき、指定口座へお振込みいただきます。"
              />
              <Step
                step={6}
                title="提出"
                description="入金確認後、税理士の署名付き確定申告書を税務署に提出します。これで確定申告完了です。提出後の税務調査の対応もお任せください。"
              />
            </div>
            <div className={style.clear} />
          </section>
          <PriceTable />
          <div className={style.linkWrap}>
            <ContactLink />
          </div>
        </div>
      </div>
      <div className={style.bottom} />
    </section>
  );
}
