import React from 'react';
import { Helmet } from 'react-helmet';

import '@/styles/base.scss';

import Header from '@/components/Header';
import Hero from '@/components/Hero';
import Why from '@/components/Why';
import Price from '@/components/Price';
import Achievement from '@/components/Achievement';
import Voice from '@/components/Voice';
import Qa from '@/components/Qa';
import Footer from '@/components/Footer';

const title = `チャットレディ・メールレディの確定申告・税金はチャトサポ`;
const description = `初回相談無料！チャトサポでは、税理士によるチャットレディ・メールレディの税金のご相談、確定申告の実績が豊富です。お客様満足度も最高位！税金や確定申告がわからない、不安で相談したい、バレたくない、確定申告をお願いしたい等々、迷ったらまずはチャトサポに無料相談！`;
const siteName = `チャトサポ`;
const domain = `https://chatsupo.com/`;
const ogImage = `${domain}ogp.jpg`;

export default function Home() {
  return (
    <>
      <Helmet
        htmlAttributes={{
          lang: `ja`,
        }}
      >
        <meta name="viewport" content="width=device-width" />
        <title>{title}</title>
        <meta content={description} name="description" />

        {/* OGP */}
        <meta property="og:description" content={description} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={domain} />
        <meta property="og:image" content={ogImage} />
        <meta property="og:site_name" content={siteName} />
        <meta name="thumbnail" content={`${domain}thumbnail.jpg`} />

        {/* Twitter */}
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:image" content={ogImage} />
        <meta name="twitter:url" content={domain} />

        <link rel="canonical" href={domain} />

        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link
          rel="preconnect"
          href="https://fonts.gstatic.com"
          crossOrigin=""
        />
        <link
          href="https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap"
          rel="stylesheet"
        />
      </Helmet>
      <main>
        <Header />
        <main>
          <Hero />
          <Why />
          <Price />
          <Achievement />
          <Voice />
          <Qa />
        </main>
        <Footer />
      </main>
    </>
  );
}
