import React from 'react';

import * as style from '@/components/Qa.module.scss';
import { QuestionMark } from '@/components/modules/Icon';
import { QA_LIST } from '@/constants/Qa';
import ContactLink from '@/components/modules/ContactLink';
import QuestionBox from '@/components/modules/QuestionBox';

export default function Qa() {
  const sections = QA_LIST.map((v) => {
    const contents = v.contents.map((c) => (
      <QuestionBox q={c.q} a={c.a} key={c.q} />
    ));
    return (
      <section className={style.section} key={v.title}>
        <h2 className={style.sectionHeading}>{v.title}</h2>
        <ul>{contents}</ul>
      </section>
    );
  });
  return (
    <section id="qa" className={style.block}>
      <div className={style.heading}>
        <QuestionMark className={style.headingIcon} />
        <div className={style.headingImgWrap}>
          <img
            alt="お悩みQ&A"
            className={style.headingImg}
            src="/common/heading/5.svg"
          />
        </div>
      </div>
      <div className={style.sectionList}>{sections}</div>
      <div className={style.linkWrap}>
        <ContactLink />
      </div>
    </section>
  );
}
