import React from 'react';

function QuestionMark(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M11.9924 23.9849C13.6379 23.9849 15.1849 23.6745 16.6334 23.0536C18.082 22.4328 19.359 21.5672 20.4643 20.4568C21.5697 19.3464 22.4353 18.0694 23.0612 16.6259C23.6871 15.1823 24 13.6379 24 11.9924C24 10.347 23.6871 8.80253 23.0612 7.359C22.4353 5.91547 21.5672 4.6385 20.4568 3.52809C19.3464 2.41768 18.0694 1.55206 16.6259 0.931221C15.1823 0.310407 13.6328 0 11.9773 0C10.342 0 8.8 0.310407 7.35142 0.931221C5.90285 1.55206 4.6284 2.41768 3.52808 3.52809C2.42776 4.6385 1.56466 5.91547 0.9388 7.359C0.312934 8.80253 0 10.347 0 11.9924C0 13.6379 0.312934 15.1823 0.9388 16.6259C1.56466 18.0694 2.43028 19.3464 3.53565 20.4568C4.64101 21.5672 5.91798 22.4328 7.36656 23.0536C8.81515 23.6745 10.3571 23.9849 11.9924 23.9849ZM11.9924 22.2133C10.5792 22.2133 9.25426 21.9508 8.01766 21.4259C6.78107 20.901 5.6959 20.1691 4.76215 19.2303C3.8284 18.2915 3.09906 17.2038 2.57413 15.9672C2.04922 14.7306 1.78676 13.4057 1.78676 11.9924C1.78676 10.5792 2.04922 9.25427 2.57413 8.01767C3.09906 6.78108 3.8284 5.69338 4.76215 4.75456C5.6959 3.81577 6.77855 3.08391 8.01009 2.55901C9.24163 2.03408 10.564 1.77161 11.9773 1.77161C13.4006 1.77161 14.7306 2.03408 15.9672 2.55901C17.2038 3.08391 18.2889 3.81577 19.2227 4.75456C20.1565 5.69338 20.8908 6.78108 21.4259 8.01767C21.9609 9.25427 22.2284 10.5792 22.2284 11.9924C22.2284 13.4057 21.9634 14.7306 21.4334 15.9672C20.9035 17.2038 20.1716 18.2915 19.2379 19.2303C18.3041 20.1691 17.2189 20.901 15.9823 21.4259C14.7457 21.9508 13.4158 22.2133 11.9924 22.2133Z" />
      <path d="M11.7654 14.4303C12.0279 14.4303 12.2348 14.3571 12.3862 14.2107C12.5377 14.0643 12.6134 13.8852 12.6134 13.6732C12.6134 13.6429 12.6134 13.6126 12.6134 13.5823C12.6134 13.5521 12.6134 13.5167 12.6134 13.4763C12.6134 13.1432 12.7118 12.8505 12.9086 12.5981C13.1055 12.3457 13.4159 12.0782 13.8399 11.7956C14.4254 11.412 14.8922 11.0158 15.2405 10.6069C15.5888 10.1981 15.7629 9.65048 15.7629 8.96405C15.7629 8.03534 15.402 7.31609 14.6803 6.80632C13.9585 6.29652 13.103 6.04163 12.1137 6.04163C11.1143 6.04163 10.3017 6.25362 9.67584 6.67759C9.04998 7.10157 8.66133 7.59117 8.5099 8.14639C8.44934 8.32809 8.41907 8.51483 8.41907 8.70663C8.41907 8.9489 8.4973 9.1306 8.65376 9.25173C8.81022 9.37286 8.97425 9.43342 9.14586 9.43342C9.33768 9.43342 9.49667 9.39557 9.62284 9.31986C9.74903 9.24415 9.85251 9.13564 9.93326 8.99431L10.1149 8.73689C10.2866 8.3634 10.5389 8.05804 10.872 7.8208C11.2052 7.58359 11.5989 7.46499 12.0531 7.46499C12.6285 7.46499 13.0853 7.61388 13.4235 7.91167C13.7616 8.20945 13.9307 8.59052 13.9307 9.05488C13.9307 9.46877 13.8071 9.80946 13.5597 10.077C13.3124 10.3445 12.9263 10.6599 12.4014 11.0233C11.9572 11.3363 11.6014 11.6694 11.3339 12.0227C11.0664 12.376 10.9326 12.8303 10.9326 13.3855C10.9326 13.4259 10.9326 13.4612 10.9326 13.4915C10.9326 13.5218 10.9326 13.5571 10.9326 13.5975C10.9326 14.1527 11.2102 14.4303 11.7654 14.4303ZM11.7503 17.8978C12.0733 17.8978 12.3509 17.7842 12.5831 17.5571C12.8153 17.33 12.9314 17.0599 12.9314 16.747C12.9314 16.4139 12.8153 16.1388 12.5831 15.9218C12.3509 15.7047 12.0733 15.5962 11.7503 15.5962C11.4273 15.5962 11.1471 15.7073 10.9099 15.9293C10.6727 16.1514 10.5541 16.424 10.5541 16.747C10.5541 17.0599 10.6727 17.33 10.9099 17.5571C11.1471 17.7842 11.4273 17.8978 11.7503 17.8978Z" />
    </svg>
  );
}

export default QuestionMark;
