import React from 'react';

import * as style from './Header.module.scss';
import {
  Answer,
  Balloon,
  Bars,
  Contact,
  Heart,
  MainLogo,
  Yen,
  QuestionMark,
  SubLogo,
  Cross,
} from '@/components/modules/Icon';
import { LINK } from '@/constants/Link';
import ContactLink from '@/components/modules/ContactLink';

const LIST = [
  {
    href: `#why`,
    icon: Answer,
    text: `確定申告とは`,
  },
  {
    href: `#price`,
    icon: Yen,
    text: `流れ・料金`,
  },
  {
    href: `#achievement`,
    icon: Heart,
    text: `安心・実績`,
  },
  {
    href: `#voice`,
    icon: Balloon,
    text: `お客様の声`,
  },
  {
    href: `#qa`,
    icon: QuestionMark,
    text: `お悩みQ&A`,
  },
  {
    href: LINK.form,
    icon: Contact,
    text: `お問い合わせ`,
    isContact: true,
  },
];

export default function Header() {
  const [isOpen, setIsOpen] = React.useState(false);
  const handleClick = () => setIsOpen(!isOpen);

  React.useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = `hidden`;
      return;
    }
    document.body.style.overflow = ``;
  }, [isOpen]);

  const pcItems = LIST.map((v) => {
    const Icon = v.icon;
    return (
      <li className={style.item} data-content={v.isContact ? `contact` : ``}>
        <a className={style.link} href={v.href}>
          <Icon className={style.icon} />
          <div className={style.linkText}>{v.text}</div>
        </a>
      </li>
    );
  });

  const spItems = LIST.filter((v) => !v.isContact).map((v) => {
    const Icon = v.icon;
    return (
      <li className={style.item}>
        <a className={style.link} href={v.href} onClick={handleClick}>
          <Icon className={style.icon} />
          <div className={style.linkText}>{v.text}</div>
        </a>
      </li>
    );
  });

  return (
    <header className={style.header}>
      <nav className={style.nav}>
        <div className={style.logo}>
          <MainLogo className={style.logoImg} />
          <SubLogo className={style.logoText} />
        </div>
        <ul className={style.list}>{pcItems}</ul>
        <div className={style.hamburger}>
          <button
            aria-label={isOpen ? `リンクを閉じる` : `リンクを開く`}
            type="button"
            className={style.button}
            onClick={handleClick}
          >
            {isOpen ? <Cross /> : <Bars />}
          </button>
        </div>
        {isOpen && (
          <div className={style.spMenu}>
            <ul className={style.list}>{spItems}</ul>
            <ContactLink />
          </div>
        )}
      </nav>
    </header>
  );
}
