import React from 'react';

import * as style from './ContactLink.module.scss';

import { Contact } from '@/components/modules/Icon';
import { LINK } from '@/constants/Link';

export default function ContactLink() {
  return (
    <a className={style.link} href={LINK.form}>
      <Contact className={style.icon} />
      <span className={style.text}>無料で問い合わせ</span>
    </a>
  );
}
