import React from 'react';

function Bars(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect y="4" width="24" height="1.5" rx="0.75" />
      <rect y="11" width="24" height="1.5" rx="0.75" />
      <rect y="18" width="24" height="1.5" rx="0.75" />
    </svg>
  );
}

export default Bars;
