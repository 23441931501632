import React from 'react';

function Cross(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M1.51471 1.51471C1.80761 1.22181 2.28248 1.22181 2.57537 1.51471L22.4853 21.4246C22.7782 21.7175 22.7782 22.1924 22.4853 22.4853C22.1924 22.7782 21.7175 22.7782 21.4246 22.4853L1.51471 2.57536C1.22182 2.28247 1.22182 1.8076 1.51471 1.51471Z" />
      <path d="M1.51471 22.4853C1.22181 22.1924 1.22181 21.7175 1.51471 21.4246L21.4246 1.51472C21.7175 1.22183 22.1924 1.22183 22.4853 1.51472C22.7782 1.80761 22.7782 2.28249 22.4853 2.57538L2.57536 22.4853C2.28247 22.7782 1.8076 22.7782 1.51471 22.4853Z" />
    </svg>
  );
}

export default Cross;
