import React from 'react';

function MainLogo(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="134"
      height="28"
      viewBox="0 0 134 28"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby="title"
      {...props}
    >
      <title>チャトサポ</title>
      <path d="M0 12.2793C0 10.5295 0.874889 9.65464 2.62467 9.65464H9.71921L9.71917 5.25081H4.72777C2.97799 5.25081 2.1031 4.37592 2.1031 2.62614C2.1031 0.876364 2.97799 0.00147397 4.72777 0.00147397L18.8887 0.00146484C20.6385 0.00146484 21.5134 0.876355 21.5134 2.62613C21.5134 4.37591 20.6385 5.2508 18.8887 5.2508L15.0695 5.25081L15.0695 9.65464H20.9918C22.7416 9.65464 23.6165 10.5295 23.6165 12.2793C23.6165 14.0291 22.7416 14.904 20.9918 14.904H15.0358C14.8339 17.1024 14.2058 20.0667 13.1514 21.5697C12.0971 23.0727 10.9306 24.2953 9.65187 25.2375C8.39561 26.1797 6.73557 27.0321 4.67173 27.7949C3.12385 28.3332 2.04706 27.7949 1.44137 26.1797C0.925406 24.4748 1.4638 23.3531 3.05655 22.8147C5.36715 22.052 6.98233 21.1098 7.90209 19.9881C8.82184 18.8665 9.39393 16.4294 9.61826 14.904H2.62467C0.874889 14.904 0 14.0291 0 12.2793Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M38.4498 9.62314H45.4581C47.2472 9.5993 48.6541 9.92321 49.6023 11.1947C50.565 12.4627 50.6593 13.6049 49.9967 15.3795L49.9923 15.3915L49.9871 15.4032C49.1673 17.2477 48.1301 19.1316 46.3863 20.4165C45.712 20.9134 44.994 21.1514 44.3249 21.0933C43.6452 21.0343 43.0617 20.6723 42.6838 20.0645L42.6796 20.0578L42.6757 20.0509C42.2889 19.3733 42.1928 18.7139 42.3434 18.1001C42.4913 17.497 42.9017 17.0229 43.3326 16.5677C43.9194 15.9479 44.7582 14.9071 44.7582 14.9071H44.4345H44.4338H39.0784L40.3731 25.224C40.4712 25.9539 40.3618 26.6027 39.9654 27.1019C39.5713 27.5981 38.9538 27.8705 38.199 27.9801L38.1852 27.9821L38.1713 27.9832C37.3873 28.0444 36.704 27.9019 36.1798 27.4911C35.6551 27.0799 35.3558 26.4516 35.2331 25.6781L35.2318 25.6699L33.8192 14.9039H32.4833H32.475C31.7663 14.9039 31.1593 14.7286 30.7175 14.2723C30.283 13.8235 30.0636 13.1851 30.0031 12.423L30.0018 12.4064V12.3897C30.0018 11.6045 30.1763 10.9351 30.5883 10.445C31.0067 9.94723 31.6154 9.67581 32.3451 9.62549H32.3541H33.1966L33.0661 6.92645C32.9697 6.13098 33.1 5.43425 33.5422 4.91228C33.9817 4.39348 34.6629 4.12662 35.4948 4.04223L35.5126 4.04042L35.5306 4.04013C36.3118 4.02793 36.9798 4.207 37.4669 4.64393C37.9543 5.0812 38.1941 5.71687 38.2446 6.47803L38.4498 9.62314Z"
      />
      <path d="M57.1137 25.3419L57.1137 2.65978C57.1137 0.88757 57.9998 0.00146484 59.772 0.00146484C61.5442 0.00146484 62.4303 0.88757 62.4303 2.65978V9.62398H72.6766C74.4264 9.62398 75.3013 10.4989 75.3013 12.2486C75.3013 14.0209 74.4264 14.907 72.6766 14.907H62.4303V25.3419C62.4303 27.1141 61.5442 28.0002 59.772 28.0002C57.9998 28.0002 57.1137 27.1141 57.1137 25.3419Z" />
      <path d="M84.2419 15.8155L84.2419 9.49137H83.5017C81.707 9.49137 80.8097 8.59405 80.8097 6.7994C80.8097 5.00476 81.707 4.10744 83.5017 4.10744H84.2419L84.2419 2.65978C84.2419 0.977303 85.0832 0.136063 86.7656 0.136063C88.4706 0.136063 89.323 0.977303 89.323 2.65978L89.323 4.10744H95.7501L95.7501 2.52518C95.7501 0.842705 96.5913 0.00146484 98.2738 0.00146484C99.9787 0.00146484 100.831 0.842705 100.831 2.52518L100.831 4.10744H101.74C103.534 4.10744 104.432 5.00476 104.432 6.7994C104.432 8.59405 103.534 9.49137 101.74 9.49137H100.831L100.831 15.58C100.786 17.9354 100.293 19.9096 99.3506 21.5023C98.4084 23.0726 97.0848 24.4074 95.3799 25.5066C93.675 26.5834 91.6336 27.391 89.2557 27.9294C87.5732 28.221 86.5413 27.5929 86.16 26.045C85.8908 24.2503 86.4628 23.2409 87.8761 23.0165C90.0072 22.5454 91.5214 21.9846 92.4188 21.3341C93.3161 20.6835 94.09 19.8983 94.7406 18.9786C95.3912 18.0588 95.7276 16.926 95.7501 15.58L95.7501 9.49137H89.323L89.323 15.8155C89.323 17.498 88.4818 18.3392 86.7993 18.3392C85.0944 18.3392 84.2419 17.498 84.2419 15.8155Z" />
      <path d="M114.488 24.3952C115.119 21.367 115.581 17.565 115.604 14.2673C115.559 12.8989 114.864 12.1698 113.518 12.0801C111.97 12.0801 111.173 12.8092 111.129 14.2673C111.106 17.0939 110.609 20.7529 109.945 24.126C109.676 25.4944 110.304 26.2571 111.83 26.4141C113.333 26.616 114.219 25.943 114.488 24.3952Z" />
      <path d="M127.218 24.3952C126.586 21.367 126.124 17.565 126.102 14.2673C126.147 12.8989 126.842 12.1698 128.188 12.0801C129.736 12.0801 130.532 12.8092 130.577 14.2673C130.6 17.0939 131.097 20.7529 131.76 24.126C132.03 25.4944 131.402 26.2571 129.876 26.4141C128.373 26.616 127.487 25.943 127.218 24.3952Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M118.288 9.48918V25.5034C118.288 27.1635 119.129 27.9935 120.811 27.9935C122.472 27.9935 123.302 27.1635 123.302 25.5034V9.48918H129.129C130.924 9.48918 131.821 8.59186 131.821 6.79722C131.821 5.8283 131.559 5.12094 131.036 4.67514C131.037 4.70364 131.038 4.73243 131.038 4.7615C131.038 5.28662 130.85 5.72422 130.475 6.0743C130.125 6.42438 129.688 6.59942 129.162 6.59942C128.637 6.59942 128.2 6.42438 127.85 6.0743C127.5 5.72422 127.325 5.28662 127.325 4.7615C127.325 4.52412 127.357 4.30537 127.422 4.10525H123.302V2.49007C123.302 0.830024 122.46 0 120.778 0C119.118 0 118.288 0.830024 118.288 2.49007V4.10525H112.577C110.782 4.10525 109.885 5.00257 109.885 6.79722C109.885 8.59186 110.782 9.48918 112.577 9.48918H118.288Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M129.16 0C127.844 0 126.728 0.457819 125.812 1.37346C124.896 2.31771 124.439 3.44795 124.439 4.76418C124.439 6.08042 124.896 7.19635 125.812 8.11199C126.728 9.02763 127.844 9.48545 129.16 9.48545C130.476 9.48545 131.592 9.02763 132.508 8.11199C133.452 7.19635 133.924 6.08042 133.924 4.76418C133.924 3.44795 133.452 2.33202 132.508 1.41638C131.592 0.472125 130.476 0 129.16 0ZM131.038 4.7615C131.038 4.21137 130.85 3.76126 130.475 3.41118C130.125 3.0611 129.688 2.88606 129.162 2.88606C128.637 2.88606 128.2 3.0611 127.85 3.41118C127.5 3.76126 127.325 4.21137 127.325 4.7615C127.325 5.28662 127.5 5.72422 127.85 6.0743C128.2 6.42438 128.637 6.59942 129.162 6.59942C129.688 6.59942 130.125 6.42438 130.475 6.0743C130.85 5.72422 131.038 5.28662 131.038 4.7615Z"
      />
    </svg>
  );
}

export default MainLogo;
