import React from 'react';

function Balloon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_21_2194)">
        <path d="M11.986 24.0682C12.2007 24.0682 12.4107 24.0075 12.6161 23.8862C12.8215 23.7648 13.0222 23.5408 13.2182 23.2141L15.2765 19.8255H19.1552C20.1914 19.8255 21.0712 19.6365 21.7946 19.2584C22.5181 18.8803 23.0665 18.3296 23.4399 17.6061C23.8133 16.8827 24 16.0122 24 14.9947V6.36926C24 5.35175 23.8133 4.48126 23.4399 3.75781C23.0665 3.03435 22.5181 2.48359 21.7946 2.10554C21.0712 1.72748 20.1914 1.53845 19.1552 1.53845H4.84482C3.79931 1.53845 2.91715 1.72748 2.19836 2.10554C1.47958 2.48359 0.9335 3.03202 0.560109 3.75081C0.186703 4.4696 0 5.34242 0 6.36926V14.9947C0 16.0215 0.186703 16.8943 0.560109 17.6131C0.9335 18.3319 1.47958 18.8803 2.19836 19.2584C2.91715 19.6365 3.79931 19.8255 4.84482 19.8255H8.68146L10.7398 23.2141C10.9265 23.5408 11.1249 23.7648 11.3349 23.8862C11.5449 24.0075 11.762 24.0682 11.986 24.0682ZM11.986 22.4859L10.0817 19.0554C9.91366 18.738 9.7503 18.5279 9.5916 18.4253C9.43291 18.3226 9.18087 18.2712 8.83548 18.2712H4.84482C3.74329 18.2712 2.91948 17.9889 2.3734 17.4241C1.8273 16.8593 1.55425 16.0449 1.55425 14.9807V6.38325C1.55425 5.31907 1.8273 4.50461 2.3734 3.93985C2.91948 3.37508 3.74329 3.09271 4.84482 3.09271H19.1412C20.2334 3.09271 21.0549 3.37508 21.6056 3.93985C22.1564 4.50461 22.4318 5.31907 22.4318 6.38325V14.9807C22.4318 16.0449 22.1564 16.8593 21.6056 17.4241C21.0549 17.9889 20.2334 18.2712 19.1412 18.2712H15.1225C14.7678 18.2712 14.5134 18.3226 14.3594 18.4253C14.2054 18.5279 14.0443 18.738 13.8763 19.0554L11.986 22.4859Z" />
      </g>
      <defs>
        <clipPath id="clip0_21_2194">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default Balloon;
