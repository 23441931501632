// extracted by mini-css-extract-plugin
export var block = "Achievement-module--block--Av-Y+";
export var heading = "Achievement-module--heading--y6PVL";
export var headingIcon = "Achievement-module--headingIcon--Duuzc";
export var headingImgWrap = "Achievement-module--headingImgWrap--RTZ61";
export var headingImg = "Achievement-module--headingImg--dR72B";
export var headingText = "Achievement-module--headingText--jm4yz";
export var section = "Achievement-module--section--dg5qG";
export var mainSection = "Achievement-module--mainSection--pOt6A";
export var sectionGroup = "Achievement-module--sectionGroup--hQkZ5";
export var textSection = "Achievement-module--textSection--tjZ20";
export var sectionHeading = "Achievement-module--sectionHeading--ZHaqA";
export var paragraph = "Achievement-module--paragraph--T9lVP";
export var imgWrap = "Achievement-module--imgWrap--E9o5z";
export var img = "Achievement-module--img--EcKb0";
export var graphSection = "Achievement-module--graphSection--IZr+X";
export var graphHeading = "Achievement-module--graphHeading--g0FDC";
export var spBreak = "Achievement-module--spBreak--Ayj96";
export var graphGroup = "Achievement-module--graphGroup--ojv-e";
export var graphImgWrap = "Achievement-module--graphImgWrap--QMKV2";
export var graphText = "Achievement-module--graphText--mGzwT";
export var graphImg = "Achievement-module--graphImg--TCn4A";
export var graphList = "Achievement-module--graphList--yqejc";
export var linkWrap = "Achievement-module--linkWrap--asccH";