import React from 'react';

import * as style from '@/components/Voice.module.scss';
import { Balloon } from '@/components/modules/Icon';
import { VOICE_LIST } from '@/constants/Voice';
import ContactLink from '@/components/modules/ContactLink';

export default function Voice() {
  const voices = VOICE_LIST.map((v) => (
    <li className={style.item} key={v.img}>
      <div className={style.data}>
        <img alt="" className={style.img} src={v.img} loading="lazy" />
        <div>{v.info}</div>
        <div>{v.job}</div>
        <div>{v.salary}</div>
      </div>
      <p
        className={style.text}
        dangerouslySetInnerHTML={{ __html: v.text.replace(/\r?\n/g, `<br />`) }}
      />
    </li>
  ));
  return (
    <section id="voice" className={style.block}>
      <div className={style.top} />
      <div className={style.contentWrap}>
        <div className={style.content}>
          <div className={style.heading}>
            <Balloon className={style.headingIcon} />
            <div className={style.headingImgWrap}>
              <img
                alt="お客様の声"
                className={style.headingImg}
                src="/common/heading/4.svg"
              />
            </div>
          </div>
          <ul className={style.list}>{voices}</ul>
          <div className={style.linkWrap}>
            <ContactLink />
          </div>
        </div>
      </div>
      <div className={style.bottom} />
    </section>
  );
}
