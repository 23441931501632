import React from 'react';

import * as style from './Why.module.scss';
import { Exclamation } from '@/components/modules/Icon';
import ContactLink from '@/components/modules/ContactLink';

export default function Why() {
  return (
    <section id="why" className={style.block}>
      <div className={style.heading}>
        <Exclamation className={style.headingIcon} />
        <div className={style.headingImgWrap}>
          <img
            alt="確定申告とは"
            className={style.headingImg}
            src="/common/heading/1.svg"
          />
        </div>
      </div>
      <div className={style.sectionGroup}>
        <section className={style.section}>
          <div className={style.imgWrap}>
            <img
              alt=""
              className={style.img}
              src="/common/section_1/1.jpg"
              loading="lazy"
            />
          </div>
          <div className={style.textWrap}>
            <div className={style.textInner}>
              <h3 className={style.sectionHeading}>確定申告って必要なの？</h3>
              <p className={style.paragraph}>
                チャットレディ・メールレディ・テレフォンレディとしての所得が次の金額を超える場合には、確定申告が必要です。
              </p>
              <ul className={style.list}>
                <li>
                  お仕事がチャトレ等だけの人：
                  <span className={style.em}>48万円/年</span>
                </li>
                <li>
                  OLなどのお昼の仕事や本業が別にある人：
                  <span className={style.em}>20万円/年</span>
                </li>
              </ul>
              <span className={style.note}>
                ※所得＝収入から経費を引いた金額
              </span>
            </div>
          </div>
        </section>
        <section className={style.section}>
          <div className={style.imgWrap}>
            <img
              alt=""
              className={style.img}
              src="/common/section_1/2.jpg"
              loading="lazy"
            />
          </div>
          <div className={style.textWrap}>
            <div className={style.textInner}>
              <h3 className={style.sectionHeading}>
                確定申告しないとどうなるの？
              </h3>
              <p className={style.paragraph}>
                自宅に税務署から問い合わせが来て、様々な質問をされたり、確定申告をするように言われることがあります。
                <br />
                場合によっては、税金以上のペナルティを課せられて
                <span className={style.em}>お金を払わなければならない</span>
                ことも…。怖いですよね。
                <br />
              </p>
              <p className={style.paragraph}>
                自分だけでなく、家族にバレて迷惑を掛けることもあります。申告が必要かどうかを正しく判断しましょう！
              </p>
            </div>
          </div>
        </section>
        <section className={style.section}>
          <div className={style.imgWrap}>
            <img
              alt=""
              className={style.img}
              src="/common/section_1/3.jpg"
              loading="lazy"
            />
          </div>
          <div className={style.textWrap}>
            <div className={style.textInner}>
              <h3 className={style.sectionHeading}>確定申告ってどうやるの？</h3>
              <p className={style.paragraph}>
                確定申告書という書類を作成して税務署に提出します。
                書類の作り方は以下の3つがあります。
              </p>
              <ul className={style.list}>
                <li>自分で作成する</li>
                <li>税務署などの無料相談会に行く</li>
                <li>税理士に依頼する</li>
              </ul>
              <p className={style.paragraph}>
                確定申告書には、チャトレ等の収入と経費・その他のお仕事の収入の内容などを書きます。
                <br />
                <span className={style.em}>
                  収入が把握できる書類（勤務先からもらう明細書や通知書など）や経費の領収書などは捨てずに保管してください。
                </span>
              </p>
            </div>
          </div>
        </section>
        <section className={style.section}>
          <div className={style.imgWrap}>
            <img
              alt=""
              className={style.img}
              src="/common/section_1/4.jpg"
              loading="lazy"
            />
          </div>
          <div className={style.textWrap}>
            <div className={style.textInner}>
              <h3 className={style.sectionHeading}>
                自分で作れない場合はどうするの？
              </h3>
              <p className={style.paragraph}>
                「チャットレディ
                確定申告」と検索すれば、確定申告のおおまかなやり方が出てきます。
              </p>
              <ul className={style.list}>
                <li>自分で作ってみたけど、これで合っているのかわからない</li>
                <li>そもそもこんなの作れない…</li>
              </ul>
              <p className={style.paragraph}>
                そんな方は、チャトサポにお任せ！
                <br />
                <span className={style.em}>
                  スマホで領収書の写真をアップするなど、簡単な作業をしていただくだけで、税理士が確定申告書をお作りします。
                </span>
                <br />
                簡単・安心ですので、お気軽にお問い合わせください。
                <br />
                初回のオンライン面談は無料です。
              </p>
            </div>
          </div>
        </section>
      </div>
      <div className={style.linkWrap}>
        <ContactLink />
      </div>
    </section>
  );
}
