import React from 'react';

function Answer(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_21_2196)">
        <path d="M11.9924 24.4849C13.6379 24.4849 15.1849 24.1745 16.6334 23.5536C18.082 22.9328 19.359 22.0672 20.4643 20.9568C21.5697 19.8464 22.4353 18.5694 23.0612 17.1259C23.6871 15.6823 24 14.1379 24 12.4924C24 10.847 23.6871 9.30253 23.0612 7.859C22.4353 6.41547 21.5672 5.1385 20.4568 4.02809C19.3464 2.91768 18.0694 2.05206 16.6259 1.43122C15.1823 0.810407 13.6328 0.5 11.9773 0.5C10.342 0.5 8.8 0.810407 7.35142 1.43122C5.90285 2.05206 4.6284 2.91768 3.52808 4.02809C2.42776 5.1385 1.56466 6.41547 0.9388 7.859C0.312934 9.30253 0 10.847 0 12.4924C0 14.1379 0.312934 15.6823 0.9388 17.1259C1.56466 18.5694 2.43028 19.8464 3.53565 20.9568C4.64101 22.0672 5.91798 22.9328 7.36656 23.5536C8.81515 24.1745 10.3571 24.4849 11.9924 24.4849ZM11.9924 22.7133C10.5792 22.7133 9.25426 22.4508 8.01766 21.9259C6.78107 21.401 5.6959 20.6691 4.76215 19.7303C3.8284 18.7915 3.09906 17.7038 2.57413 16.4672C2.04922 15.2306 1.78676 13.9057 1.78676 12.4924C1.78676 11.0792 2.04922 9.75427 2.57413 8.51767C3.09906 7.28108 3.8284 6.19338 4.76215 5.25456C5.6959 4.31577 6.77855 3.58391 8.01009 3.05901C9.24163 2.53408 10.564 2.27161 11.9773 2.27161C13.4006 2.27161 14.7306 2.53408 15.9672 3.05901C17.2038 3.58391 18.2889 4.31577 19.2227 5.25456C20.1565 6.19338 20.8908 7.28108 21.4259 8.51767C21.9609 9.75427 22.2284 11.0792 22.2284 12.4924C22.2284 13.9057 21.9634 15.2306 21.4334 16.4672C20.9035 17.7038 20.1716 18.7915 19.2379 19.7303C18.3041 20.6691 17.2189 21.401 15.9823 21.9259C14.7457 22.4508 13.4158 22.7133 11.9924 22.7133Z" />
        <path d="M8.10099 18.0647C8.48459 18.0647 8.7521 17.8375 8.90352 17.3833L9.82717 14.7486H14.1426L15.0663 17.3833C15.2278 17.8375 15.5004 18.0647 15.8839 18.0647C16.1161 18.0647 16.3054 17.9991 16.4518 17.8678C16.5981 17.7366 16.6713 17.5599 16.6713 17.3379C16.6713 17.1965 16.631 17.0148 16.5502 16.7928L13.1433 7.69244C12.9414 7.13723 12.5527 6.85962 11.9773 6.85962C11.412 6.85962 11.0234 7.13723 10.8114 7.69244L7.41959 16.7928C7.34893 17.0148 7.3136 17.1965 7.3136 17.3379C7.3136 17.5599 7.38426 17.7366 7.52559 17.8678C7.66691 17.9991 7.85872 18.0647 8.10099 18.0647ZM10.2512 13.4767L11.9319 8.72209H12.0379L13.7186 13.4767H10.2512Z" />
      </g>
      <defs>
        <clipPath id="clip0_21_2196">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default Answer;
