export const VOICE_LIST = [
  {
    img: `/common/section_4/1.png`,
    info: `東京都 26歳`,
    job: `主婦とチャットレディ`,
    salary: `収入500万円`,
    text: `旦那も公認でチャットレディを本業として仕事しています。チャトサポさんはネット検索して1番ピンときたので選びました。\n\n確定申告までの作業は、全部お任せしているのでこちらがやるとこで面倒に感じたことは一切ないです。\n\nこれからも引き続きお願いします。`,
  },
  {
    img: `/common/section_4/2.png`,
    info: `埼玉県 30歳`,
    job: `会社員とチャットレディ`,
    salary: `収入150万円`,
    text: `副業としてチャットレディをしていて、会社や主人には内緒です。あとから罰金があったり、会社や主人にバレる可能性があると思い、確定申告をお願いしました。\n\n検索でチャトサポが初めに出てきて、信頼できそうだったので相談しました。作業はとても簡単で、領収書をアップしただけで、あとはすべてお任せです。\nまた、パパ活や別の事業をはじめる時にも丁寧に相談に乗っていただきとても助かりました。\n\n連絡や対応も早くて丁寧です。わからないこともすぐに教えてくれて、今後もぜひお願いしたいと思います。`,
  },
  {
    img: `/common/section_4/3.png`,
    info: `岡山県 20歳`,
    job: `アルバイトとチャットレディ`,
    salary: `収入100万円`,
    text: `家族には内緒でチャットレディをしています。\n家族にバレないように税金のことをやってくれる人いないかなって探していたら、Google検索でチャトサポが上位だったので問い合わせしました。\n\n確定申告の作業がアプリを通して行うためとても簡単でした。\n仕事や連絡なども早くて満足です。また、価格も他と比べても良心的です。本当にありがとうございました。`,
  },
  {
    img: `/common/section_4/4.png`,
    info: `福岡県 37歳`,
    job: `主婦とチャットレディ`,
    salary: `収入200万円`,
    text: `主婦をしながらチャットレディをしています。家族には内緒です。\n開業の書類を提出しておらず、夫の扶養からも外れそうで不安になり、検索してチャトサポさんに頼みました。\n\n問い合わせからすぐにZoomでの面談になり、わかりやすく、丁寧だったので依頼しました。\nfreeeのアプリで、初めはうまくいかないこともありましたが、写真を撮ったりするだけで、あとはスムーズにできたのでよかったです。\nわからないことや聞きたいこともチャットワークですぐに聞けるので助かります。\n費用については、初めに思っていたよりも高く驚きましたが、理由は私のアップした領収書が予想より多すぎたからで、色々な対応や、青色申告の控除を考えれば納得しています。\n\n初めての確定申告でしたが、扶養のままで家族に内緒でいたいということもクリアしてよかったです。またお願いします。`,
  },
  {
    img: `/common/section_4/5.png`,
    info: `秋田県 24歳`,
    job: `会社員とテレフォンレディ`,
    salary: `収入200万円`,
    text: `会社員をしながらテレフォンレディをしていて、会社には秘密にしています。\n似た業種のチャットレディさんでの確定申告の実績があると知ったので、チャトサポさんに依頼しました。\n\n確定申告の作業について、丁寧にやり方を教えて下さるので分かりやすかったです。\n今後とも宜しくお願い致します。`,
  },
  {
    img: `/common/section_4/6.png`,
    info: `東京都 45歳`,
    job: `主婦とチャットレディ`,
    salary: `収入400万円`,
    text: `仕事はチャットレディだけで、家族にはネット系の仕事をしていると言っています。\n確定申告は初めてで自分ではよくわからなかったので、確実で安心できそうなチャトサポさんを選びました。\n\n申告の作業は思ったより簡単でした。ただ、後でやろうと思って、freeeの使い方を忘れてしまいそうになるので、なるべく小まめにやることにしています。\n他の税理士はよくわかりませんが、連絡もこまめにしていただき、親切に進めてもらっていると思います。\n\nどうしていいかわからない中、今回初めてでしたがお世話になり、本当に感謝しています。至らぬところもありますが、今後もよろしくお願いします。`,
  },
];
