import React from 'react';

import * as style from './Achievement.module.scss';
import { Heart } from '@/components/modules/Icon';
import ContactLink from '@/components/modules/ContactLink';

export default function Achievement() {
  return (
    <section id="achievement" className={style.block}>
      <div className={style.heading}>
        <Heart className={style.headingIcon} />
        <div className={style.headingImgWrap}>
          <img
            alt="安心・実績"
            className={style.headingImg}
            src="/common/heading/3.svg"
          />
        </div>
      </div>
      <section className={style.section}>
        <div className={style.mainSection}>
          <section className={style.textSection}>
            <h2 className={style.sectionHeading}>経験豊富な税理士が対応</h2>
            <div className={style.imgWrap}>
              <img
                alt=""
                className={style.img}
                src="/common/section_3/1.png"
                loading="lazy"
              />
              <div>
                <p className={style.paragraph}>
                  一般的な会計事務所は、税理士資格のないスタッフが対応することが多く、知識が不十分なことも…。
                </p>
                <p className={style.paragraph}>
                  チャトサポでは税理士がお客様の対応をします。税理士の署名付き確定申告書を提出することで、信頼度が高まります。
                  <br />
                  万が一、税務調査が入る場合には、お客様ではなく税理士宛に連絡がきます。ご安心ください。
                </p>
              </div>
            </div>
          </section>
        </div>
        <div className={style.sectionGroup}>
          <section className={style.textSection}>
            <h2 className={style.sectionHeading}>安心のセキュリティ</h2>
            <p className={style.paragraph}>
              税理士には守秘義務が定められています。そのため、お客様の情報を他に流出することはありません。
            </p>
            <p className={style.paragraph}>
              また、お客様とのやり取りは基本的にはオンラインにて行うため、ご安心していただけます。
            </p>
            <p className={style.paragraph}>
              <span>※希望される場合にはご来社によるご面談も可能です。</span>
            </p>
          </section>
          <section className={style.textSection}>
            <h2 className={style.sectionHeading}>会社や家族にバレない</h2>
            <p className={style.paragraph}>
              ご依頼を受けた場合には、お客様のお仕事の状況を誰が知っていて、誰が知ってはいけないのか等のアンケートを取っています。
              <br />
              そのアンケートをもとに会社や家族にバレないよう対応させていただきます。
            </p>
          </section>
        </div>
      </section>
      <section className={style.graphSection}>
        <h2 className={style.graphHeading}>
          こんなお客様がチャトサポを
          <br className={style.spBreak} />
          ご利用しています
        </h2>
        <div className={style.graphGroup}>
          <figure className={style.graphImgWrap}>
            <figcaption className={style.graphText}>年齢別円グラフ</figcaption>
            <img
              alt=""
              className={style.graphImg}
              src="/common/section_3/2.png"
              loading="lazy"
            />
            <ul className={style.graphList}>
              <li>20歳から24歳、15.8%</li>
              <li>25歳から29歳、26.3%</li>
              <li>30歳から34歳、21.1%</li>
              <li>35歳から39歳、21.1%</li>
              <li>45歳から49歳、15.8%</li>
            </ul>
          </figure>
          <figure className={style.graphImgWrap}>
            <figcaption className={style.graphText}>収入別円グラフ</figcaption>
            <img
              alt=""
              className={style.graphImg}
              src="/common/section_3/3.png"
              loading="lazy"
            />
            <ul className={style.graphList}>
              <li>100から199万円、46.7%</li>
              <li>200から299万円、13.3%</li>
              <li>400から499万円、13.3%</li>
              <li>500から599万円、6.7%</li>
              <li>700から799万円、13.3%</li>
              <li>800から899万円、6.7%</li>
            </ul>
          </figure>
        </div>
      </section>
      <div className={style.linkWrap}>
        <ContactLink />
      </div>
    </section>
  );
}
