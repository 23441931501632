import React from 'react';

function ArrowDown(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M12.009 18.5394C12.3331 18.5394 12.6151 18.4073 12.8552 18.1433L23.6579 6.92648C23.886 6.6984 24 6.43433 24 6.13426C24 5.81019 23.892 5.54013 23.6759 5.32408C23.4599 5.10803 23.1958 5 22.8837 5C22.5716 5 22.2956 5.10803 22.0555 5.32408L11.3068 16.4689H12.6932L1.92647 5.32408C1.71042 5.10803 1.44636 5 1.13429 5C0.810195 5 0.540124 5.10803 0.324071 5.32408C0.108024 5.54013 0 5.81019 0 6.13426C0 6.43433 0.114034 6.7044 0.342102 6.94447L11.1448 18.1613C11.3968 18.4133 11.6849 18.5394 12.009 18.5394Z" />
    </svg>
  );
}

export default ArrowDown;
