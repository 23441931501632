import React from 'react';

import * as style from './PriceTable.module.scss';

function Amount({ price }: { price: string }) {
  return (
    <div className={style.amount}>
      <span className={style.amountPrice}>{price}</span>
      <span className={style.amountUnit}>万円〜</span>
      <span className={style.amountTax}>(税抜)</span>
    </div>
  );
}

export default function PriceTable() {
  return (
    <section className={style.block}>
      <h2 className={style.heading}>料金</h2>
      <section className={style.subSection}>
        <Amount price="8" />
        <div className={style.subSectionParagraph}>
          収入の種類や金額、取引数に応じて変動します。
          <br className={style.pcBreak} />
          詳細はお問い合わせください。
        </div>
      </section>
      <section className={style.priceSection}>
        <h2 className={style.priceHeading}>料金例</h2>
        <div className={style.exampleList}>
          <div className={style.exampleItem}>
            <div className={style.exampleText}>
              チャットレディの収入
              <br />
              200万円の場合
            </div>
            <Amount price="8" />
          </div>
          <div className={style.exampleItem}>
            <div className={style.exampleText}>
              給与とチャットレディの収入
              <br />
              400万円の場合
            </div>
            <Amount price="12.5" />
          </div>
        </div>
      </section>
    </section>
  );
}
