// extracted by mini-css-extract-plugin
export var block = "Step-module--block--AJAur";
export var content = "Step-module--content--iWVu-";
export var imgWrap = "Step-module--imgWrap--9Pta4";
export var stepText = "Step-module--stepText--cd9Fa";
export var step = "Step-module--step--wXQay";
export var stepType = "Step-module--stepType--81AkF";
export var img = "Step-module--img--w7wzb";
export var textWrap = "Step-module--textWrap--C9+Dm";
export var textHeading = "Step-module--textHeading--lXY2p";
export var textParagraph = "Step-module--textParagraph--4AwqD";