import React from 'react';

function Contact(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M3.32867 21.4546H20.937C21.9161 21.4546 22.6713 21.1795 23.2028 20.6294C23.7343 20.0793 24 19.2587 24 18.1678V6.06994C24 4.97903 23.7203 4.15619 23.1608 3.6014C22.6014 3.04662 21.7716 2.76923 20.6713 2.76923H3.06294C2.09325 2.76923 1.34033 3.04429 0.804185 3.59441C0.268062 4.14454 0 4.96971 0 6.06994V18.1678C0 19.2587 0.279719 20.0793 0.839158 20.6294C1.3986 21.1795 2.22844 21.4546 3.32867 21.4546ZM3.2867 19.986C2.69931 19.986 2.25175 19.8322 1.94405 19.5245C1.63636 19.2168 1.48252 18.7599 1.48252 18.1539V6.06994C1.48252 5.47321 1.63636 5.02099 1.94405 4.71328C2.25175 4.40559 2.69931 4.25175 3.2867 4.25175H20.7133C21.2821 4.25175 21.7249 4.40559 22.042 4.71328C22.359 5.02099 22.5175 5.47321 22.5175 6.06994V18.1678C22.5175 18.7646 22.359 19.2168 22.042 19.5245C21.7249 19.8322 21.2821 19.986 20.7133 19.986H3.2867ZM12.028 14.9371C12.3916 14.9371 12.7552 14.8578 13.1189 14.6993C13.4825 14.5408 13.8508 14.2797 14.2238 13.9161L23.1748 5.13288L22.1538 4.11188L13.3706 12.7692C13.1282 13.0023 12.8974 13.1725 12.6783 13.2797C12.4592 13.387 12.2424 13.4406 12.028 13.4406C11.8135 13.4406 11.5967 13.387 11.3776 13.2797C11.1585 13.1725 10.9277 13.0023 10.6853 12.7692L1.86015 4.06995L0.853136 5.09091L9.83217 13.9161C10.2051 14.2797 10.5734 14.5408 10.9371 14.6993C11.3007 14.8578 11.6643 14.9371 12.028 14.9371ZM22.042 20.0979L23.049 19.0769L15.8462 12L14.8392 13.007L22.042 20.0979ZM0.979024 19.0909L1.99999 20.1259L9.23077 13.007L8.20978 12L0.979024 19.0909Z" />
    </svg>
  );
}

export default Contact;
