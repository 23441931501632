// extracted by mini-css-extract-plugin
export var block = "Price-module--block--tg-Gl";
export var top = "Price-module--top--nGSBp";
export var bottom = "Price-module--bottom--KX3zs";
export var contentWrap = "Price-module--contentWrap--8GIFh";
export var content = "Price-module--content--O2xL4";
export var heading = "Price-module--heading--xvIzU";
export var headingIcon = "Price-module--headingIcon--0gGie";
export var headingImgWrap = "Price-module--headingImgWrap--53d+y";
export var headingImg = "Price-module--headingImg--kxFvH";
export var headingText = "Price-module--headingText--J5Eug";
export var section = "Price-module--section--oKwEg";
export var sectionHeadingGroup = "Price-module--sectionHeadingGroup--skeFn";
export var sectionHeadingWrap = "Price-module--sectionHeadingWrap--JzKI4";
export var sectionHeading = "Price-module--sectionHeading--QEj-8";
export var clear = "Price-module--clear--stHAQ";
export var linkWrap = "Price-module--linkWrap--O4FWG";