import React from 'react';

import * as style from './Hero.module.scss';

export default function Hero() {
  return (
    <section className={style.block}>
      <div className={style.imgWrap}>
        <picture>
          <source
            media="(max-width: 999px)"
            srcSet="/sp/KV.jpg"
            width="600"
            height="672"
          />
          <source
            media="(min-width: 1000px)"
            srcSet="/pc/KV.jpg"
            width="1440"
            height="620"
          />
          <img
            className={style.img}
            src="/pc/KV.jpg"
            alt="チャットレディの収入は確定申告が必要です。写真をアップするだけで確定申告が出来る。顧客満足度97.5％"
          />
        </picture>
      </div>
      <picture>
        <source
          media="(max-width: 999px)"
          srcSet="/sp/KV_b.jpg"
          width="600"
          height="64"
        />
        <source
          media="(min-width: 1000px)"
          srcSet="/pc/KV_b.jpg"
          width="600"
          height="64"
        />
        <img className={style.bottom} src="/pc/KV.jpg" alt="" />
      </picture>
    </section>
  );
}
