import React from 'react';

function Exclamation(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M11.9924 23.9849C13.6379 23.9849 15.1849 23.6745 16.6334 23.0536C18.082 22.4328 19.359 21.5672 20.4643 20.4568C21.5697 19.3464 22.4353 18.0694 23.0612 16.6259C23.6871 15.1823 24 13.6379 24 11.9924C24 10.347 23.6871 8.80253 23.0612 7.359C22.4353 5.91547 21.5672 4.6385 20.4568 3.52809C19.3464 2.41768 18.0694 1.55206 16.6259 0.931221C15.1823 0.310407 13.6328 0 11.9773 0C10.342 0 8.8 0.310407 7.35142 0.931221C5.90285 1.55206 4.6284 2.41768 3.52808 3.52809C2.42776 4.6385 1.56466 5.91547 0.9388 7.359C0.312934 8.80253 0 10.347 0 11.9924C0 13.6379 0.312934 15.1823 0.9388 16.6259C1.56466 18.0694 2.43028 19.3464 3.53565 20.4568C4.64101 21.5672 5.91798 22.4328 7.36656 23.0536C8.81515 23.6745 10.3571 23.9849 11.9924 23.9849ZM11.9924 22.2133C10.5792 22.2133 9.25426 21.9508 8.01766 21.4259C6.78107 20.901 5.6959 20.1691 4.76215 19.2303C3.8284 18.2915 3.09906 17.2038 2.57413 15.9672C2.04922 14.7306 1.78676 13.4057 1.78676 11.9924C1.78676 10.5792 2.04922 9.25427 2.57413 8.01767C3.09906 6.78108 3.8284 5.69338 4.76215 4.75456C5.6959 3.81577 6.77855 3.08391 8.01009 2.55901C9.24163 2.03408 10.564 1.77161 11.9773 1.77161C13.4006 1.77161 14.7306 2.03408 15.9672 2.55901C17.2038 3.08391 18.2889 3.81577 19.2227 4.75456C20.1565 5.69338 20.8908 6.78108 21.4259 8.01767C21.9609 9.25427 22.2284 10.5792 22.2284 11.9924C22.2284 13.4057 21.9634 14.7306 21.4334 15.9672C20.9035 17.2038 20.1716 18.2915 19.2379 19.2303C18.3041 20.1691 17.2189 20.901 15.9823 21.4259C14.7457 21.9508 13.4158 22.2133 11.9924 22.2133Z" />
      <path d="M11.9924 14.1275C12.5173 14.1275 12.7848 13.8398 12.7949 13.2644L12.9463 6.96532C12.9564 6.69276 12.8681 6.46562 12.6813 6.28392C12.4946 6.10222 12.2599 6.01137 11.9772 6.01137C11.6845 6.01137 11.4473 6.10222 11.2656 6.28392C11.0839 6.46562 10.998 6.69276 11.0081 6.96532L11.1444 13.2644C11.1646 13.8398 11.4472 14.1275 11.9924 14.1275ZM11.9924 17.8978C12.3053 17.8978 12.5804 17.7842 12.8176 17.5571C13.0548 17.33 13.1734 17.0599 13.1734 16.747C13.1734 16.4139 13.0573 16.1388 12.8252 15.9218C12.593 15.7047 12.3154 15.5962 11.9924 15.5962C11.6592 15.5962 11.3766 15.7073 11.1444 15.9293C10.9122 16.1514 10.7961 16.424 10.7961 16.747C10.7961 17.0599 10.9148 17.33 11.152 17.5571C11.3892 17.7842 11.6693 17.8978 11.9924 17.8978Z" />
    </svg>
  );
}

export default Exclamation;
