// extracted by mini-css-extract-plugin
export var nav = "Header-module--nav--YCIXU";
export var logo = "Header-module--logo--m5asN";
export var header = "Header-module--header--3ERvK";
export var logoImg = "Header-module--logoImg--7eI5Y";
export var logoText = "Header-module--logoText--9BZxg";
export var list = "Header-module--list--LFc44";
export var hamburger = "Header-module--hamburger--MVanz";
export var button = "Header-module--button--qwUh2";
export var spMenu = "Header-module--spMenu--8IdbP";
export var item = "Header-module--item--m4ouf";
export var link = "Header-module--link--XPSsj";
export var icon = "Header-module--icon--HokS6";
export var linkText = "Header-module--linkText--XVMrY";