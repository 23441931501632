import React from 'react';

function Triangle(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M3.07731 22.762H20.908C21.5362 22.762 22.081 22.6221 22.5424 22.3423C23.0037 22.0626 23.362 21.692 23.6172 21.2307C23.8724 20.7693 24 20.2491 24 19.6699C24 19.4147 23.9656 19.1571 23.8969 18.8969C23.8282 18.6368 23.7252 18.389 23.5878 18.1534L14.6503 2.56071C14.3558 2.05029 13.9706 1.66257 13.4945 1.39753C13.0184 1.13251 12.5202 1 12 1C11.4798 1 10.9791 1.13251 10.4982 1.39753C10.0172 1.66257 9.63437 2.05029 9.34971 2.56071L0.412271 18.1534C0.137424 18.6344 0 19.1399 0 19.6699C0 20.2491 0.125154 20.7693 0.375461 21.2307C0.625761 21.692 0.984046 22.0626 1.45032 22.3423C1.91657 22.6221 2.4589 22.762 3.07731 22.762ZM3.09202 21.1571C2.66994 21.1571 2.32393 21.0074 2.05398 20.708C1.78404 20.4086 1.64907 20.0626 1.64907 19.6699C1.64907 19.562 1.66134 19.4442 1.68588 19.3165C1.71043 19.189 1.75706 19.0614 1.82577 18.9337L10.7485 3.32637C10.8761 3.09078 11.0552 2.91409 11.2859 2.79631C11.5166 2.67851 11.7546 2.61961 12 2.61961C12.2454 2.61961 12.4785 2.67851 12.6994 2.79631C12.9203 2.91409 13.1043 3.09078 13.2515 3.32637L22.1595 18.9485C22.2871 19.1742 22.3509 19.4147 22.3509 19.6699C22.3509 20.0626 22.216 20.4086 21.946 20.708C21.6761 21.0074 21.3252 21.1571 20.8933 21.1571H3.09202Z" />
      <path d="M12.0147 15.0319C12.5252 15.0319 12.7853 14.7522 12.7951 14.1927L12.9424 8.06751C12.9522 7.80247 12.8663 7.58161 12.6847 7.40492C12.5031 7.22824 12.2749 7.13989 12 7.13989C11.7154 7.13989 11.4847 7.22824 11.308 7.40492C11.1313 7.58161 11.0479 7.80247 11.0577 8.06751L11.1902 14.1927C11.2098 14.7522 11.4847 15.0319 12.0147 15.0319ZM12.0147 18.6982C12.319 18.6982 12.5865 18.5878 12.8172 18.3669C13.0479 18.146 13.1632 17.8835 13.1632 17.5792C13.1632 17.2552 13.0503 16.9877 12.8246 16.7767C12.5988 16.5657 12.3289 16.4601 12.0147 16.4601C11.6908 16.4601 11.416 16.5681 11.1902 16.7841C10.9645 17 10.8516 17.265 10.8516 17.5792C10.8516 17.8835 10.9669 18.146 11.1976 18.3669C11.4283 18.5878 11.7007 18.6982 12.0147 18.6982Z" />
    </svg>
  );
}

export default Triangle;
